import React from "react";

const NDA = () => {
    return (
        <div className="prose max-w-none">
            <p>The present Non-Disclosure Agreement (hereinafter referred to as the &quot;NDA&quot;) makes provision for the terms and conditions in line with which you are authorized to enter the closed playtest for &ldquo;Hood &ndash; Outlaws &amp; Legend&rdquo; organized by Focus Home Interactive, a company entered on the Paris Trade and Companies Register under number 399 856 277 and having its registered office at Parc Pont de Flandre &ldquo;Le Beauvaisis&ldquo;, B&acirc;timent 28, 11 Rue Cambrai 75019 Paris, France.</p>
            <p>&nbsp;YOU SHOULD CAREFULLY READ THIS NDA BEFORE ENTERING THE CLOSED BETA PLAYTEST. IN SUCH INSTANCE AS YOU DO NOT ACCEPT THE TERMS AND CONDITIONS SET FORTH UNDER THE PRESENT NDA, YOU ARE NOT AUTHORISED TO ENTER THE CLOSED BETA PLAYTEST.</p>
            <p>For the purpose of the following provisions, the term &ldquo;Recipient&rdquo; shall be used to refer to you. The term &ldquo;Focus&rdquo; shall be used to refer to Focus Home Interactive.</p>
            <ol>
                <li>Confidential Information means all information about the Closed Beta playtest and/or the game entitled &ldquo;Hood &ndash; Outlaws &amp; Legend&rdquo; (including, without limitation, gameplay, soundtrack, game dynamics, game story, characters, graphics, decors and all other aspects of the game), regardless the nature of the medium (e.g. images, photographs, videos, drawings, sounds, etc.), which will be disclosed to the Recipient, orally or in writing, in the course of the abovementioned Closed Beta playtest.</li>
                <li>By way of derogation, it shall not be considered as confidential the information that:
                    <ol>
                        <li>is already public knowledge prior to Focus&rsquo; disclosure of the same to the Recipient;</li>
                        <li>has become public knowledge through legal means without fault by the Recipient;</li>
                        <li>is already known to the Recipient prior to Focus&rsquo; disclosure of the same pursuant to this NDA, provided that such information is received from a third party whose disclosure would not violate any confidentiality obligation, at Recipient&rsquo; knowledge.</li>
                    </ol>
                </li>
            </ol>
            <ol>
                <li>Recipient undertakes to:
<ol>
                        <li>maintain the confidential and private nature of Confidential Information;</li>
                        <li>restrict its use of Confidential Information to work performed in conjunction with Focus related to such possible collaboration and to ensure that dissemination of Confidential Information within its own organization is on a strict &quot;need to know&quot; basis and provided that the Recipient undertakes to ensure that such persons comply with the provisions of this NDA.</li>
                        <li>not to make any use of the Confidential Information for any purposes other than those set out in the preamble to this NDA.</li>
                    </ol>
                </li>
            </ol>
            <ol>
                <li>Notwithstanding any other prohibited disclosure, the Recipient is not allowed to stream, publish screenshot, disclose any part of the game or its elements, nor talk about the game outside of the closed beta forum or the closed Discord channel, if any.</li>
                <li>All Confidential Information and materials furnished by Focus to the Recipient shall remain the property of Focus and nothing contained herein is intended as granting the Receiver any rights or license with respect to the Confidential Information disclosed or any intellectual property rights hereto.</li>
                <li>Receiver shall promptly return to the Discloser all documents and any tangible material or medium containing or representing Confidential Information, and all copies or extracts thereof or notes derived therefrom that are in possession of Recipient, each upon Focus&rsquo; written request.</li>
                <li>Unless otherwise terminated by mutual consent in writing, this NDA shall continue in force for two years.</li>
                <li>This NDA constitutes the entire agreement between the parties and supersedes all previous agreements, understandings and undertakings with respect to the subject matter of this NDA.</li>
                <li>French Law applies to this NDA. The exclusive place of jurisdiction for any possible disputes arising out of this NDA is agreed to be the courts of Paris, France.</li>
            </ol>
        </div>
    )
}

export default NDA