import React, { useEffect, useRef, useState } from "react";
import jwt from "jsonwebtoken";
import { useMutation, gql } from '@apollo/client';

import Layout from "../components/layout";
import SEO from "../components/seo";
import NDA from "./../components/NDA"
import Banner from "./../images/hood-banner.jpeg";
import logo from "./../images/hood-logo.png";
import logoSteam from "../images/logo-steam.png";

function IndexPage() {
  const [submitted, setSubmitted] = useState(false)
  const [form, setForm] = useState({
    email: '',
    region: 'europe',
    discord: '',
    focus: '',
    platform: 'pc',
    specs: 'low',
    steam: '',
    newsletter: false
  });
  const [NDAaccepted, setNDAaccepted] = useState(false)
  const [openedModal, setOpenedModal] = useState(false)
  const [steamUser, setSteamUser] = useState({})
  const [errorSteam, setErrorSteam] = useState("")
  const [errorDiscord, setErrorDiscord] = useState("")
  const [error, setError] = useState("")
  const [createSubscriber, { loading }] = useMutation(CREATE_SUBSCRIBER);
  const NDARef = useRef();

  const specs = [
    {
      name: 'Low',
      CPU: 'Intel Core i5-6400k @2.70GHz',
      RAM: '8GB',
      GPU: 'GeForce GTX 960',
      VRAM: '2GB'
    },
    {
      name: 'Medium',
      CPU: 'Intel Core i7-5820k @3.30GHz',
      RAM: '16GB',
      GPU: 'GeForce GTX 970',
      VRAM: '4GB'
    },
    {
      name: 'High',
      CPU: 'Intel Core i7-8700k @3.70GHz',
      RAM: '16GB',
      GPU: 'GeForce GTX 1080',
      VRAM: '8GB'
    }
  ]

  useEffect(() => {
    window.addEventListener("message", event => {
      if (event.origin !== process.env.GATSBY_API_URL) return;

      const { token, ok } = event.data;
      if (ok) {
        const { user } = jwt.verify(token, process.env.GATSBY_API_SECRET);
        setSteamUser(user);
        setErrorSteam('')
      }
    });
  }, []);

  const ndaChange = () => {
    setNDAaccepted(NDARef.current.checked)
  }

  const openModal = value => {
    setOpenedModal(value);
  }

  const updateField = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const checkDiscordName = e => {
    const value = e.target.value;
    if (value && !value.match(/\w+#\d{4}/i)) {
      setErrorDiscord(true)
    } else {
      setErrorDiscord(false)
    }
  }

  const handleClick = async () => {
    const popupWindow = window.open(
      `${process.env.GATSBY_API_URL}/auth/steam`,
      "_blank",
      "width=800, height=600",
    );
    if (window.focus) popupWindow.focus();
  }

  const deleteAccount = () => {
    setSteamUser({});
    setForm({ ...form, steam: null })
  }

  const prepareSubmit = () => {
    if (form.platform === 'pc' && !steamUser.id) {
      setErrorSteam('You must login with Steam')
      return;
    }

    const subscriber = {
      ...form
    }

    if (form.platform === 'pc') {
      subscriber.steam = steamUser.id;
      setErrorSteam('');
    } else {
      subscriber.steam = '';
      subscriber.specs = 'low';
    }

    if (window.location.href.split("?id=").length > 1) {
      subscriber.token = window.location.href.split("?id=")[1].substring(0, 10);
    }

    return subscriber;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const subscriber = prepareSubmit();
    if (subscriber) {
      try {
        await createSubscriber({
          variables: subscriber
        });
        setSubmitted(true)
      } catch (er) {
        setError(er);
      }
    }
  }

  return (
    <Layout>
      <SEO
        title="Beta"
      />
      <div className={`${openedModal ? 'bg-opacity-50' : 'bg-opacity-0 pointer-events-none'} fixed top-0 left-0 z-30 flex items-center justify-center w-full h-full py-20 bg-black `}>
        <div className="absolute top-0 left-0 w-full h-full" onClick={() => openModal(false)}></div>
        {openedModal && <div className="z-20 w-full max-w-4xl max-h-full px-6 overflow-y-scroll text-gray-900 bg-white md:px-16">
          <div className="py-8 md:py-16">
            <NDA />
          </div>
          <button type="button" onClick={() => {
            openModal(false);
            setNDAaccepted(true)
          }}
            className="inline-flex items-center justify-center w-full px-6 py-3 mb-4 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-blue-500 border-2 border-white hover:bg-blue-700 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700">Accept</button>
        </div>
        }
      </div>
      <section className="pt-8">
        <img className="mx-auto md:max-w-3xl" alt="Hood: Outlaws & Legends" src={logo} />
        <div className="relative mt-4">
          <div className="absolute top-0 left-0 z-10 w-full h-24 bg-gradient-to-b from-blue-hood"></div>
          <div className="absolute bottom-0 left-0 z-10 w-full h-24 bg-gradient-to-t from-blue-hood"></div>
          <img className="object-cover object-center w-full h-64 md:h-auto" alt="" src={Banner} />
        </div>
        {!submitted && (
          <>
            <h1 className="relative z-20 px-4 -mt-12 text-2xl text-center uppercase sm:text-3xl font-display sm:px-0">The brotherhood wants you</h1>
            <div className="max-w-2xl px-4 mx-auto sm:px-0">
              <p className="mt-2">Sign up for our Beta Newsletter for a chance to be one of the first to play Hood: Outlaws & Legends in our closed Beta. With your help and feedback, we will make the game the best it can be! If you don&apos;t make it into the first Beta, don&apos;t worry - there will be several waves of invites before the game launches.</p>
              <p className="mt-2">Please keep in mind that if you are invited to the Beta you will need to sign an NDA and be unable to discuss the contents of the Beta with anyone outside of it.</p>
              <p className="mt-6">Requirements :</p>
              <ul>
                <li>- Being 18 and over</li>
                <li>- Having a PC</li>
                <li>- Having a Steam account</li>
                <li>- Speaking English or French to give feedback to the development team</li>
              </ul>
              <div className="mt-6">
                <p className="font-medium">Before you continue with the registration process, you must read and accept <button type="button" className="inline-flex underline" onClick={() => openModal(true)}>the Non-Disclosure Agreement</button> governing your access to the closed beta of &quot;Hood - Outlaws & Legends&quot;.</p>
                <div className="relative flex items-start px-2 mt-4">
                  <div className="absolute flex items-end h-5">
                    <input id="NDA" checked={NDAaccepted} required name="NDA" type="checkbox" ref={NDARef} onChange={ndaChange} className="w-4 h-4 text-blue-700 transition duration-150 ease-in-out rounded-none form-checkbox" />
                  </div>
                  <div className="pl-8 text-sm leading-6">
                    <label htmlFor="NDA" className="block font-medium text-gray-100 select-none">I understand and accept the terms of <button type="button" className="inline-flex underline" onClick={() => openModal(true)}>the Non-Disclosure Agreement</button> related to the closed beta of &quot;Hood - Outlaws & Legends&quot;.</label>
                  </div>
                </div>
              </div>
            </div>
          </>)}
        {submitted && (
          <>
            <h1 className="relative z-20 px-4 -mt-12 text-2xl text-center uppercase sm:text-3xl font-display sm:px-0">Thanks for your registration!</h1>
            <p className="max-w-2xl px-4 mx-auto mt-2 sm:px-0">
              We&apos;ll send you an email in the coming weeks if you&apos;re selected. Don&apos;t worry if you don&apos;t, as we&apos;ll run several Betas.</p>
          </>)}
      </section>
      <section className="w-full max-w-2xl px-4 py-8 mx-auto md:py-16">
        {!submitted && NDAaccepted &&
          <>
            <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-y-6 gap-x-6 sm:grid-cols-2 sm:gap-y-8">
              <div className="sm:col-span-2">
                <label htmlFor="email" className="block text-sm font-medium leading-5">Email*</label>
                <div className="relative mt-1 shadow-sm">
                  <input required id="email" name="email" type="email" onChange={updateField} value={form.email} className="block w-full px-4 py-3 text-gray-700 transition duration-150 ease-in-out rounded-none form-input" />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label htmlFor="region" className="block text-sm font-medium leading-5">Region*</label>
                <select id="region" name="region" onChange={updateField} value={form.region} className="block w-full py-3 pl-4 pr-10 mt-1 text-base leading-6 text-gray-700 border-gray-300 rounded-none form-select focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
                  <option value="africa">Africa</option>
                  <option value="america">America</option>
                  <option value="asia">Asia</option>
                  <option value="europe">Europe</option>
                  <option value="oceania">Oceania</option>
                </select>
              </div>
              <div>
                <label htmlFor="discord" className="flex text-sm font-medium leading-5">Discord nickname
                <span className="relative group">
                    <svg className="w-5 h-5 ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <div className="absolute left-0 z-10 w-48 px-3 py-1 mt-2 text-xs leading-5 text-white transition duration-75 ease-in origin-top-left transform scale-95 bg-gray-800 border border-red-500 shadow-md opacity-0 pointer-events-none group-hover:opacity-100 group-hover:scale-100">
                      Don&apos;t forget your Discord tag (nickname#1234).
                    </div>
                  </span>
                </label>
                <div className="relative mt-1 shadow-sm">
                  <input id="discord" name="discord" onChange={updateField} onBlur={checkDiscordName} value={form.discord} className="block w-full px-4 py-3 text-gray-700 transition duration-150 ease-in-out rounded-none form-input" />
                </div>
                {errorDiscord && (<p className="inline-block p-2 mt-1 text-sm border border-red-500">Your Discord tag is required (nickname#1234)</p>)}
              </div>
              <div>
                <label htmlFor="focus" className="block text-sm font-medium leading-5">Focus Forum account</label>
                <div className="relative mt-1 shadow-sm">
                  <input id="focus" name="focus" onChange={updateField} value={form.focus} className="block w-full px-4 py-3 text-gray-700 transition duration-150 ease-in-out rounded-none form-input" />
                </div>
                <p className="mt-1 text-sm">You can create an account <a href="https://forums.focus-home.com/register" target="_blank" rel="noreferrer" className="border-b border-dashed hover:border-solid">here</a>.</p>
              </div>
              {form.platform === 'pc' && (
                <>
                  <div className="">
                    <label htmlFor="steam" className="block text-sm font-medium leading-5">Steam account*</label>
                    {!steamUser.id && <button type="button" className="inline-flex items-center justify-between px-6 py-3 mt-1 text-base font-medium leading-6 text-gray-700 bg-white border border-white" onClick={() => handleClick()}>
                      <span>Login with</span>
                      <span>
                        <img
                          loading="lazy"
                          alt="Steam"
                          className="h-5 ml-3"
                          src={logoSteam}
                        />
                      </span>
                    </button>}
                    {errorSteam && <p className="inline-block p-2 mt-1 text-sm border border-red-500">{errorSteam}</p>}
                    {steamUser.id &&
                      <div className="flex group">
                        <div className="inline-flex items-center justify-start px-4 py-3 mt-1 border border-gray-700">
                          <img src={steamUser.photos?.[0].value} className="border border-blue-700" alt="steam picture" />
                          <p className="ml-3">{steamUser.displayName}</p>
                        </div>
                        <button type="button" onClick={() => deleteAccount()} aria-label="Remove account" className="items-center justify-center hidden ml-3 group-hover:flex">
                          <svg className="w-6 h-6 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                          </svg>
                        </button>
                      </div>}

                  </div>
                  <div className="sm:col-span-2">
                    <label htmlFor="specs" className="flex text-sm font-medium leading-5">Hardware*
                    <span className="relative group">
                        <svg className="w-5 h-5 ml-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <div className="absolute left-0 z-10 w-64 px-3 py-1 mt-2 -ml-8 text-xs leading-5 text-white transition duration-75 ease-in origin-top-left transform scale-95 bg-gray-800 border border-red-500 shadow-md opacity-0 pointer-events-none sm:ml-0 group-hover:opacity-100 group-hover:scale-100">
                          <div className="py-2 space-y-3">
                            {specs.map((spec, index) => (
                              <div key={index}>
                                <p className="uppercase">{spec.name}</p>
                                <table className="border border-gray-300 mb">
                                  <tbody>
                                    <tr>
                                      <td className="px-2 py-1 border border-gray-300">OS</td>
                                      <td className="px-2 py-1 border border-gray-300">Window 10 64-bit</td>
                                    </tr>
                                    <tr>
                                      <td className="px-2 py-1 border border-gray-300">CPU</td>
                                      <td className="px-2 py-1 border border-gray-300">{spec.CPU}</td>
                                    </tr>
                                    <tr>
                                      <td className="px-2 py-1 border border-gray-300">RAM</td>
                                      <td className="px-2 py-1 border border-gray-300">{spec.RAM}</td>
                                    </tr>
                                    <tr>
                                      <td className="px-2 py-1 border border-gray-300">GPU</td>
                                      <td className="px-2 py-1 border border-gray-300">{spec.GPU}</td>
                                    </tr>
                                    <tr>
                                      <td className="px-2 py-1 border border-gray-300">VRAM</td>
                                      <td className="px-2 py-1 border border-gray-300">{spec.VRAM}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            ))}
                          </div>
                        </div>
                      </span></label>
                    <p className="mt-1 text-sm">Please pick up in the hardware closest to your computer.</p>
                    <select id="specs" name="specs" value={form.specs} onChange={updateField} className="block w-full py-3 pl-4 pr-10 mt-1 text-base leading-6 text-gray-700 border-gray-300 rounded-none form-select focus:outline-none focus:shadow-outline-blue focus:border-blue-300 sm:text-sm sm:leading-5">
                      <option value="low">Low</option>
                      <option value="medium">Medium</option>
                      <option value="high">High</option>
                    </select>
                  </div>
                </>)
              }
              <div className="mt-2 sm:col-span-2">
                <p className="text-xs text-justify">
                  You must tick the box below to get a chance to participate to the closed beta of “Hood – Outlaws & Legends”. Focus Home Interactive acts as data controller for the collection and the processing of your personal information necessary for the performance of the contractual service related to the Closed Beta test and for the sole purposes of subscription, registration, functioning of the Closed Beta test and/or any subsequent tests, performance and quality analysis of the under development game, for confidentiality purposes as part of the Closed Beta test, or any subsequent tests. In particular, Focus Home Interactive will collect email address, Steam login, Steam ID information about the region you are based in,  and may also receive in-game information in relation to your experience in the game (e.g. IP address, telemetric data including logs) collected from Sumo Digital Ltd, a studio acting as a data processor and only collecting and processing your personal information based on Focus Home Interactive’s written instructions. Additionally, you may share with us your Discord and/or a dedicated Focus Forum username, which we will use only to correspond with you about the closed beta and the performance of the  game Hood – Outlaws & Legends (optional, based on your consent that you can withdraw at any time). You have rights of access to and rectification or erasure of your personal data and to restrict or object to its processing, as well as the right to data portability, which you can exercise at any time by making a written request to the following address: personaldata@focus-home.com. You also have the right to lodge a complaint about our processing of your personal data with the competent supervisory authority, the Commission Nationale de l’Informatique et des Libertés in France. To learn more about the processing of your personal data, please refer to our personal data protection general policy.
              </p>
              </div>
              <div className="sm:col-span-2">
                <div className="relative flex items-start px-2">
                  <div className="absolute flex items-end h-5">
                    <input id="legals" required name="legals" type="checkbox" className="w-4 h-4 text-blue-700 transition duration-150 ease-in-out rounded-none form-checkbox" />
                  </div>
                  <div className="pl-8 text-sm leading-6">
                    <label htmlFor="legals" className="block font-medium text-gray-100 select-none">* I certify that I am 18 years old or more. If under 18, I understand that I am not allowed to register to this Closed Beta</label>
                  </div>
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="relative flex items-start px-2 mt-2">
                  <div className="absolute flex items-end h-5">
                    <input id="newsletter" value={form.newsletter} onClick={() => {
                      setForm({
                        ...form,
                        newsletter: !form.newsletter
                      })
                    }}
                      name="newsletter" type="checkbox" className="w-4 h-4 text-blue-700 transition duration-150 ease-in-out rounded-none form-checkbox" />
                  </div>
                  <div className="pl-8 text-sm leading-6">
                    <label htmlFor="newsletter" className="block font-medium text-gray-100 select-none">I hereby agree to subscribe to Focus Home Interactive newsletter and to receive promotional offers and information from Focus Home Interactive</label>
                  </div>
                </div>
              </div>
              <p className="text-red-500 sm:col-span-2">{error?.message}</p>
              <div className="sm:col-span-2">
                <span className="inline-flex w-full shadow-sm">
                  <button type="submit" className="inline-flex items-center justify-center w-full px-6 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-transparent border-2 border-white hover:bg-white hover:text-gray-700 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700">
                    {loading ? (
                      <>
                        <svg className="w-5 h-5 mr-3 -ml-5 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <span>Loading</span>
                      </>) : (
                        <span>Submit</span>
                      )}
                  </button>
                </span>
              </div>
            </form>
            <p className="mt-12 text-sm">* Fields marked with an asterisk (*) are mandatory.</p>
          </>
        }
      </section>
    </Layout>
  );
}

const CREATE_SUBSCRIBER = gql`
  mutation createBetaSubscriberMutation($email: String!, $platform: Platform!, $region: Region!, $discord: String, $focus: String, $steam: String, $specs: Specs, $newsletter: Boolean, $token: String) {
    createHoodBetaSubscriber(email: $email, platform: $platform, region: $region, discord: $discord, focus: $focus, steam: $steam, specs: $specs, newsletter: $newsletter, token: $token) {
      email
    }
  }
`;


export default IndexPage;
